import React from "react";
import Layout from "../components/layout";
import * as styles from "../styles/pages/gallery.module.css";
import Text from "../components/SVG/TextDivider";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";

const Card = ({ category, img, alt }) => {
  return (
    <div className={styles.card}>
      <Link to={`/gallery/${category}`}>
        <GatsbyImage className={styles.cardImg} image={img} alt={alt} />
        <p>{category}</p>
      </Link>
    </div>
  );
};

function gallery({ data }) {
  return (
    <Layout
      title={data.strapiSeo.PageTitle}
      description={data.strapiSeo.PageDescription}
    >
      <Text containerStyle={{ marginTop: "2rem" }}>Gallery</Text>
      <div className={styles.container}>
        {data.allStrapiGallery.edges.map((edge, index) => {
          return (
            <React.Fragment key={index}>
              <Card
                index={index}
                category={edge.node.Category}
                img={edge.node.thumbnail.childImageSharp.gatsbyImageData}
                alt=""
              />
            </React.Fragment>
          );
        })}
      </div>
    </Layout>
  );
}
export const query = graphql`
  query {
    allStrapiGallery {
      edges {
        node {
          Category
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
          }
        }
      }
    }
    strapiSeo(NameOfPage: { eq: "gallery" }) {
      PageDescription
      PageTitle
    }
  }
`;
export default gallery;
